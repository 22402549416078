import React from 'react';

import { StyledActivityIndicator } from './ActivityIndicator.styles';
import { TActivityIndicatorProps } from './ActivityIndicator.types';

function ActivityIndicator({ size = 24, color = 'textActive' }: TActivityIndicatorProps) {
  return (
    <StyledActivityIndicator size={size} color={color}>
      <div />
      <div />
      <div />
      <div />
    </StyledActivityIndicator>
  );
}

export default ActivityIndicator;
