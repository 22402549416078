import styled from 'styled-components';

import Box from 'components/core/Box';

import { getColor } from 'helpers/theme';

export const LottieWrapper = styled(Box)`
  svg {
    #primary g > path {
      stroke: ${getColor('illustrationPrimary')};
    }

    #secondary g > path {
      stroke: ${getColor('illustrationSecondary')};
    }
  }
`;
