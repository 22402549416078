import { useEffect, ReactPortal, ReactNode } from 'react';
import ReactDOM from 'react-dom';

function ModalCore({ blur, children }: { blur?: string; children: ReactNode }): ReactPortal {
  useEffect(() => {
    const root = document.getElementById('root') as HTMLFormElement;
    root.style.filter = `blur(${blur || '5px'})`;
    root.style.overflow = 'hidden';

    return () => {
      root.style.filter = 'none';
      root.style.overflow = 'auto';
    };
  }, [blur]);

  return ReactDOM.createPortal(children, document.body);
}

export default ModalCore;
