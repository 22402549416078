import { MouseEventHandler } from 'react';
import styled from 'styled-components';

const PressableContainer = styled.button<{ onClick?: MouseEventHandler }>`
  cursor: ${(props) => {
    if (!props.onClick) {
      return 'default';
    }
    return props.disabled ? 'not-allowed' : 'pointer';
  }} !important;
  display: contents;
  -webkit-tap-highlight-color: transparent;

  > div {
    transition: all 300ms cubic-bezier(0.15, 0.5, 0.5, 1) 0s;
  }
`;

export default PressableContainer;
