import styled from 'styled-components';

import Box from 'components/core/Box';
import Text from 'components/core/Text';

import media from 'helpers/media';

export const Container = styled(Box)`
  margin: 1.5rem 1.5rem 1.5rem 0;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  border-top-left-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
  padding: 0 1rem;
  width: 100%;
`;

export const StyledImage = styled.img`
  max-width: 65%;
`;

export const StyledStars = styled.img`
  height: 24px;
`;

export const StyledRateText = styled(Text)`
  font-size: 12px;

  ${media.m} {
    font-size: 16px;
  }
`;
