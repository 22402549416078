import React from 'react';
import { useTheme } from 'styled-components';

import { TIconProps } from '../Icon.types';

function InfoCircleIcon({ width = 24, height = 24, iconColor = 'textPrimary' }: TIconProps) {
  const { colors } = useTheme();

  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
      <path
        d="M12 16v-4m0-4h.01M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Z"
        stroke={colors[iconColor as never]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
}

export default InfoCircleIcon;
