import { lazy } from 'react';

import DashboardLayout from 'layouts/DashboardLayout';

import { TPaymentRoutes } from './Payment.types';

const Pay = lazy(() => import('./Pay'));
const Result = lazy(() => import('./Result'));
const TrustPaymentIntermediary = lazy(() => import('./TrustPaymentIntermediary'));

const routes: TPaymentRoutes = {
  trustPaymentIntermediary: {
    key: 'TrustPaymentIntermediary',
    path: '/i',
    exact: true,
    titleId: 'title.trustIntermediaryPage',
    description: 'Pay with wamo',
    component: TrustPaymentIntermediary,
  },
  pay: {
    key: 'pay',
    path: '/:referenceCode',
    exact: true,
    titleId: 'title.pay',
    description: 'Pay with wamo',
    component: Pay,
    layout: DashboardLayout,
  },
  result: {
    key: 'result',
    path: '/:referenceCode/:status',
    exact: true,
    titleId: 'title.result',
    description: 'Payment Result',
    component: Result,
    layout: DashboardLayout,
  },
};

export default routes;
