import { ReactNode } from 'react';
import { TI18nId } from 'types/common';

export enum TAlertModalType {
  success = 'success',
  warning = 'warning',
  error = 'error',
  redirect = 'redirect',
}

export type TAlertModalShowOptions = {
  title?: string;
  titleValues?: Record<string, string>;
  titleId?: TI18nId | string;
  text?: string;
  textValues?: Record<string, string>;
  textId?: TI18nId | string;
  type?: TAlertModalType;
  bottomContent?: ReactNode;
  alwaysOpen?: boolean;
  showCancelButton?: boolean;
  onCancel?: () => void;
};

export type TAlertModalRef = { show: (options: TAlertModalShowOptions) => void; hide: () => void };
