import paymentRoutes from 'pages/Payment/Payment.routes';
import invoiceRoutes from 'pages/Invoice/Invoice.routes';

import { TRoute, TRoutes } from './routes.types';

const routes: TRoutes = {
  ...paymentRoutes,
  ...invoiceRoutes,
};

export const ROUTES_BY_ORDER: TRoute[] = [
  routes.invoicePreview,
  routes.invoicePay,
  routes.trustPaymentIntermediary,
  routes.result,
  routes.pay,
];

export default routes;
