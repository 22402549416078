import { NOOP } from 'constants/noop';

import { TInvoicePreviewState, TInvoicePreviewDispatch } from './InvoiceProvider.types';

export const INITIAL_STATE: TInvoicePreviewState = {
  invoicePreviewDetail: undefined,
  loading: true,
};

export const INITIAL_DISPATCH: TInvoicePreviewDispatch = {
  setInvoicePreviewDetail: NOOP,
  clear: NOOP,
};
