import styled, { css } from 'styled-components';
import { variant, color, compose, margin, padding, textAlign } from 'styled-system';

import { textVariants } from 'theme/variants';

import { TTextProps } from './Text.types';

export const StyledText = styled.p<Partial<TTextProps>>`
  font-family: inherit;
  word-break: break-word;

  ${({ numberOfLines }) =>
    numberOfLines &&
    css`
      display: -webkit-box;
      -webkit-line-clamp: ${numberOfLines};
      -webkit-box-orient: vertical;
      overflow: hidden;
      line-break: anywhere;
    `}
  ${({ preWrap }) =>
    preWrap &&
    css`
      white-space: pre-wrap;
    `}
  ${compose(color, variant({ variants: textVariants }), margin, padding, textAlign)};

  > * {
    font-family: inherit;
  }

  ${({ strikeThrough }) =>
    strikeThrough &&
    css`
      text-decoration: line-through;
    `}
`;
