import React from 'react';

import Box from 'components/core/Box';
import Text, { TextShimmer } from 'components/core/Text';
import Button from 'components/core/Button';
import { TrustpilotIcon } from 'components/Icons';

import WAMO_PAGE from 'constants/wamoPage';
import IMAGES from 'constants/images';
import TRUSTPILOT from 'constants/trustpilot';
import { usePaymentLinkContext } from 'context/PaymentLinkProvider';
import capitalizeWords from 'helpers/capitalizeWords';

import { Container, StyledImage, StyledStars, StyledRateText } from './IntroductionBanner.styles';

function IntroductionBanner() {
  const { paymentLinkState } = usePaymentLinkContext();

  return (
    <Container alignment="center" backgroundColor="backgroundSecondary">
      <Box alignment="center" p="l" mb="xl">
        <StyledImage src={IMAGES.ledgers} alt="ledgers" />
      </Box>
      {paymentLinkState.loading ? (
        <>
          <TextShimmer mb="s" textAlign="center" width="75%" variant="titleSubsection" />
          <TextShimmer mb="m" textAlign="center" width="75%" variant="titleSubsection" />
          <TextShimmer mb="m" textAlign="center" width="90%" />
        </>
      ) : (
        <>
          <Text
            textId="components.introductionBanner.title"
            variant="titleSection"
            mb="m"
            textAlign="center"
            values={{
              merchantName: capitalizeWords(paymentLinkState.paymentLink?.merchantName),
            }}
          />
          <Text textId="components.introductionBanner.description" mb="m" textAlign="center" />
        </>
      )}
      <Box mt="l" minWidth="192px">
        <Button
          labelId="button.openAccount"
          onClick={() => {
            window.location.href = WAMO_PAGE.SIGN_UP;
          }}
        />
      </Box>
      <Box
        mt="xxl"
        mb="m"
        pb="l"
        flex={1}
        display="flex"
        flexDirection="column"
        justifyContent="flex-end">
        <Text textId="label.excellent" variant="titleSubsection" mb="m" textAlign="center" />
        <Box display="flex" flexDirection="row" alignItems="center" gap="l">
          <StyledStars src={IMAGES.stars} />
          <Box display={{ _: 'none', s: 'block' }}>
            <StyledRateText
              textId="label.trustpilot"
              values={{
                rate: TRUSTPILOT.rate,
                totalReview: TRUSTPILOT.totalReview,
              }}
              textAlign="center"
            />
          </Box>
          <TrustpilotIcon />
        </Box>
      </Box>
    </Container>
  );
}

export default IntroductionBanner;
