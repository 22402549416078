import { TBorders } from './theme.types';
import palette from './palette';

const borders: TBorders = {
  border100: {
    borderColor: palette.moon200,
    borderStyle: 'solid',
    borderWidth: '1px',
  },
  border200: {
    borderColor: palette.moon300,
    borderStyle: 'solid',
    borderWidth: '1px',
  },
  radius100: '2px',
  radius200: '4px',
  radius300: '8px',
  radius400: '12px',
  radius500: '16px',
  radius600: '20px',
  radius700: '24px',
  radius800: '28px',
};

export default borders;
