import React, { ReactElement, Suspense } from 'react';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';

import NotFound from 'pages/NotFound';

import WAMO_PAGE from 'constants/wamoPage';

import { ROUTES_BY_ORDER } from './routes';
import LoadingPage from './views/LoadingPage';
import { TRoute } from './routes.types';

function AppRouter(): ReactElement {
  function renderRoute({
    component: Component,
    key,
    path,
    exact,
    titleId,
    description,
    layout: Layout = React.Fragment,
  }: TRoute): ReactElement {
    return (
      <Route
        key={key}
        path={path}
        exact={exact}
        render={() => (
          <Layout titleId={titleId} description={description}>
            <Suspense fallback={<LoadingPage />}>
              <Component />
            </Suspense>
          </Layout>
        )}
      />
    );
  }

  return (
    <Router>
      <Switch>
        {ROUTES_BY_ORDER.map((route) => renderRoute(route))}
        <Route
          exact
          path="/"
          render={() => {
            window.location.href = WAMO_PAGE.HOME;
            return null;
          }}
        />
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
}

export default AppRouter;
