import { lazy } from 'react';

import InvoiceLayout from 'layouts/InvoiceLayout';
import DashboardLayout from 'layouts/DashboardLayout';

import { TInvoiceRoutes } from './Invoice.types';

const InvoicePreview = lazy(() => import('./InvoicePreview/InvoicePreview'));
const InvoicePay = lazy(() => import('./InvoicePay'));

const routes: TInvoiceRoutes = {
  invoicePreview: {
    key: 'InvoicePreview',
    path: '/invoices/:id',
    exact: true,
    titleId: 'title.invoicePreviewPage',
    description: 'Invoice preview with wamo',
    component: InvoicePreview,
    layout: InvoiceLayout,
  },
  invoicePay: {
    key: 'InvoicePay',
    path: '/invoices/:id/pay',
    exact: true,
    titleId: 'title.invoicePay',
    description: 'Invoice pay with wamo',
    component: InvoicePay,
    layout: DashboardLayout,
  },
};

export default routes;
