import React, { ReactElement } from 'react';

import { StyledBox } from './Box.style';
import { TBoxProps } from './Box.types';

function Box({ children, ...rest }: TBoxProps): ReactElement {
  return <StyledBox {...rest}>{children}</StyledBox>;
}

export default Box;
