import * as Sentry from '@sentry/react';

async function sentryInit() {
  Sentry.init({
    environment: process.env.REACT_APP_ENV,
    dsn: process.env.REACT_APP_SENTRY_DSN,
  });
}

if (process.env.REACT_APP_ENV !== 'development') {
  sentryInit();
}
