import { TSizing } from './theme.types';

const sizing: TSizing = {
  scale50: '4px',
  scale100: '8px',
  scale200: '16px',
  scale300: '24px',
  scale400: '32px',
  scale500: '40px',
  scale600: '48px',
  scale700: '56px',
  scale800: '64px',
};

export default sizing;
