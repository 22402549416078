import React, { createContext, ReactElement, ReactNode, useContext, useState } from 'react';

import { INITIAL_DISPATCH, INITIAL_STATE } from './PaymentLinkProvider.constants';
import { TPaymentLink, TPaymentLinkContext, TPaymentLinkState } from './PaymentLinkProvider.types';
import { usePaymentLink } from './PaymentLinkProvider.hooks';

const paymentLinkContext = createContext<TPaymentLinkContext>({
  paymentLinkState: INITIAL_STATE,
  paymentLinkDispatch: INITIAL_DISPATCH,
});

function PaymentLinkProvider({ children }: { children: ReactNode }): ReactElement {
  const [{ paymentLink, loading }, setState] = useState<TPaymentLinkState>(INITIAL_STATE);
  const { retrievePaymentLinkDetail } = usePaymentLink();

  function setPaymentLinkDetail(link: TPaymentLink) {
    setState((state) => ({
      ...state,
      paymentLink: link,
      loading: false,
    }));
  }

  const value = {
    paymentLinkState: { paymentLink, loading },
    paymentLinkDispatch: { setPaymentLinkDetail, retrievePaymentLinkDetail },
  };

  return <paymentLinkContext.Provider value={value}>{children}</paymentLinkContext.Provider>;
}

export default PaymentLinkProvider;

export const usePaymentLinkContext = (): TPaymentLinkContext => useContext(paymentLinkContext);
