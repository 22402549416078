import styled, { keyframes } from 'styled-components';
import { compose, height, variant, width } from 'styled-system';

import Box from 'components/core/Box';

import { shimmerColorVariant, shimmerShapeVariants } from './Shimmer.variants';
import { TStyledShimmerProps } from './Shimmer.types';

const animate = keyframes`
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
`;

export const StyledShimmer = styled(Box)<TStyledShimmerProps>`
  animation: ${animate} 1.5s infinite ease-out;
  background-size: 400% 100%;
  ${compose(
    variant({ prop: 'shape', variants: shimmerShapeVariants }),
    variant({ prop: 'color', variants: shimmerColorVariant }),
    width,
    height
  )}
`;
