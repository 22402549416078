import React, { PropsWithChildren, ReactElement } from 'react';
import { CachePolicies, IncomingOptions, Provider } from 'use-http';

function FetchProvider({ children }: PropsWithChildren<unknown>): ReactElement {
  const options: IncomingOptions = {
    cachePolicy: CachePolicies.NETWORK_ONLY,
  };

  return (
    <Provider url={process.env.REACT_APP_BASE_API_URL} options={options}>
      {children}
    </Provider>
  );
}

export default FetchProvider;
