import React, { ReactElement } from 'react';

import themeBorderRadius from 'theme/borders';

import { StyledShimmer } from './Shimmer.styles';
import { TShimmerProps } from './Shimmer.types';

function Shimmer({
  color = 'primary',
  shape = 'line',
  borderRadius = themeBorderRadius.radius500,
  ...rest
}: TShimmerProps): ReactElement {
  return <StyledShimmer shape={shape} color={color} borderRadius={borderRadius} {...rest} />;
}

export default Shimmer;
