import React, { ReactElement } from 'react';

import Box from 'components/core/Box';
import Text from 'components/core/Text';
import { WamoIcon } from 'components/Icons';

function NotFound(): ReactElement {
  return (
    <Box alignment="center" backgroundColor="secondaryBackground" height="100vh">
      <Box backgroundColor="primaryBackground" width="500px" padding="xxl" borderRadius="8px">
        <Box alignment="row.top.right" marginBottom="m">
          <Box flex={1}>
            <Text textId="page.notFound.title" variant="titleSubsection" />
          </Box>
          <WamoIcon width="80" height="40" />
        </Box>
        <Text variant="textBody" textId="page.notFound.description" />
      </Box>
    </Box>
  );
}

export default NotFound;
