import styled from 'styled-components';
import {
  display,
  flex,
  flexbox,
  margin,
  MarginProps,
  padding,
  PaddingProps,
  width,
  WidthProps,
} from 'styled-system';

import { MODAL_DEFAULT_PADDING, MODAL_DEFAULT_WIDTH } from './Modal.const';

export const ModalContainer = styled.div`
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  align-items: center;
  justify-content: center;
  background-color: rgba(33, 37, 41, 0.56);
  z-index: 2;
`;

export const ModalContent = styled.div<WidthProps & MarginProps & PaddingProps>(
  {
    width: MODAL_DEFAULT_WIDTH,
    padding: MODAL_DEFAULT_PADDING,
    borderRadius: 8,
    boxShadow: '0 10px 25px 0 rgba(0, 0, 0, 0.1)',
    backgroundColor: '#f5f5f5',
  },
  display,
  flex,
  flexbox,
  width,
  margin,
  padding
);
