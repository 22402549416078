import React, { createContext, ReactElement, ReactNode, useContext, useState } from 'react';

import { INITIAL_DISPATCH, INITIAL_STATE } from './InvoiceProvider.constants';
import {
  TInvoicePreviewContext,
  TInvoicePreviewState,
  TInvoicePreviewResponse,
} from './InvoiceProvider.types';

const invoicePreviewContext = createContext<TInvoicePreviewContext>({
  invoicePreviewState: INITIAL_STATE,
  invoicePreviewDispatch: INITIAL_DISPATCH,
});

function InvoiceProvider({ children }: { children: ReactNode }): ReactElement {
  const [{ invoicePreviewDetail, loading }, setState] = useState<TInvoicePreviewState>(
    INITIAL_STATE
  );

  function setInvoicePreviewDetail(invoice: TInvoicePreviewResponse) {
    setState((state) => ({
      ...state,
      invoicePreviewDetail: invoice,
      loading: false,
    }));
  }

  function clear() {
    setState(INITIAL_STATE);
  }

  const value = {
    invoicePreviewState: { invoicePreviewDetail, loading },
    invoicePreviewDispatch: { setInvoicePreviewDetail, clear },
  };

  return <invoicePreviewContext.Provider value={value}>{children}</invoicePreviewContext.Provider>;
}

export default InvoiceProvider;

export const useInvoicePreviewContext = (): TInvoicePreviewContext =>
  useContext(invoicePreviewContext);
