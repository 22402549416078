const API_ENDPOINT = {
  REFRESH_TOKEN: '/v1/refresh',
  APPLE_PAY_SESSION: '/v1/online-payment/apple-pay/session',
  APPLE_PAY_PAY: '/v1/online-payment/apple-pay/pay',
  CARD_PAY: '/v1/online-payment/card-pay/pay',
  PAYMENT_PAGE_INFO: '/v1/online-payment/detail',
  COUNTRIES: '/v1/countries',
  ACCOUNT_RECEIVABLES_INVOICES: '/invoice-api/v1/external/ari',
};

export default API_ENDPOINT;
