/* eslint-disable no-multi-assign */
import React, { ReactElement, useEffect } from 'react';

import InvoicePdfPreview from 'components/InvoicePdfPreview';

import { TInvoiceLayoutProps } from './InvoiceLayout.types';
import MainLayout from '../MainLayout';

function InvoiceLayout({ titleId, description, children }: TInvoiceLayoutProps): ReactElement {
  useEffect(() => {
    window.history.replaceState({}, document.title);
  }, []);

  return (
    <MainLayout titleId={titleId} description={description} right={<InvoicePdfPreview />}>
      {children}
    </MainLayout>
  );
}

export default InvoiceLayout;
