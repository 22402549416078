import { FONT_FAMILY, FONT_SIZE, FONT_WEIGHT } from './fonts';

import { TTextVariants } from './theme.types';

const textVariants: TTextVariants = {
  titleSection: {
    ...FONT_SIZE.titleSection,
    fontWeight: FONT_WEIGHT.bold,
    fontFamily: FONT_FAMILY,
  },
  titleSubsection: {
    ...FONT_SIZE.titleSubsection,
    fontWeight: FONT_WEIGHT.semiBold,
    fontFamily: FONT_FAMILY,
  },
  textBodyBold: {
    ...FONT_SIZE.textBody,
    fontWeight: FONT_WEIGHT.bold,
    fontFamily: FONT_FAMILY,
  },
  textBody: {
    ...FONT_SIZE.textBody,
    fontWeight: FONT_WEIGHT.regular,
    fontFamily: FONT_FAMILY,
  },
  textBodySubBold: {
    ...FONT_SIZE.textBodySub,
    fontWeight: FONT_WEIGHT.semiBold,
    fontFamily: FONT_FAMILY,
  },
  textBodySub: {
    ...FONT_SIZE.textBodySub,
    fontWeight: FONT_WEIGHT.regular,
    fontFamily: FONT_FAMILY,
  },
  textBodySmallBold: {
    ...FONT_SIZE.textSmall,
    fontWeight: FONT_WEIGHT.semiBold,
    fontFamily: FONT_FAMILY,
  },
  textBodySmall: {
    ...FONT_SIZE.textSmall,
    fontWeight: FONT_WEIGHT.regular,
    fontFamily: FONT_FAMILY,
  },
};

export { textVariants };
