/* eslint-disable no-multi-assign */
import React, { ReactElement, useEffect } from 'react';

import IntroductionBanner from 'components/IntroductionBanner';
import MainLayout from 'layouts/MainLayout';

import { TDashboardLayoutProps } from './DashboardLayout.types';

function DashboardLayout({ titleId, description, children }: TDashboardLayoutProps): ReactElement {
  useEffect(() => {
    window.history.replaceState({}, document.title);
  }, []);

  return (
    <MainLayout titleId={titleId} description={description} right={<IntroductionBanner />}>
      {children}
    </MainLayout>
  );
}

export default DashboardLayout;
