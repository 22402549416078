import React, { ReactElement, createRef } from 'react';
import { ErrorBoundary } from '@sentry/react';

import AppRouter from 'router/AppRouter';

import AllContextProvider from 'context/AllContextProvider';
import AlertModal from 'components/AlertModal';

import { TAlertModalRef } from 'components/AlertModal/AlertModal.types';

import 'configs/i18n';
import 'configs/sentry';

export const alertModalRef = createRef<TAlertModalRef>();

function App(): ReactElement {
  return (
    <ErrorBoundary fallback={<div>An error Occured</div>}>
      <AllContextProvider>
        <AppRouter />
        <AlertModal ref={alertModalRef} />
      </AllContextProvider>
    </ErrorBoundary>
  );
}

export default App;
