import React from 'react';
import Lottie from 'react-lottie';

import { LottieWrapper } from './LottieView.styles';
import { TLottieViewProps } from './LottieView.types';

function LottieView({ width, height, autoPlay = true, loop = true, source }: TLottieViewProps) {
  return (
    <LottieWrapper>
      <Lottie
        width={width}
        height={height}
        options={{
          autoplay: autoPlay,
          loop,
          animationData: source,
        }}
      />
    </LottieWrapper>
  );
}

export default LottieView;
