import styled, { keyframes } from 'styled-components';

import { TThemePrimitives } from 'theme/theme.types';
import { getColor } from 'helpers/theme';

const spinnerFrames = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const StyledActivityIndicator = styled.div<{ size: number; color: keyof TThemePrimitives }>`
  display: inline-block;
  position: relative;
  width: ${(props) => `${props.size}px`};
  height: ${(props) => `${props.size}px`};

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: ${(props) => `${props.size}px`};
    height: ${(props) => `${props.size}px`};
    border: 3px solid ${(props) => getColor(props.color)};
    border-radius: 50%;
    animation: ${spinnerFrames} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${(props) => getColor(props.color)} transparent transparent transparent;
  }

,
div: nth-child(1) {
  animation-delay: -0.45s;
},
div: nth-child(2) {
  animation-delay: -0.3s;
},
div: nth-child(3) {
  animation-delay: -0.15s;
},
`;
