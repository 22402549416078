/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useState, Ref, useImperativeHandle } from 'react';
import Lottie from 'react-lottie';

import Modal from 'components/core/Modal';
import Text from 'components/core/Text';
import Box from 'components/core/Box';

import { TI18nId } from 'types/common';

import { TAlertModalRef, TAlertModalShowOptions, TAlertModalType } from './AlertModal.types';
import { ALERT_MODAL_LOTTIE, INITIAL_STATE, SUCCESS_LOTTIE_OPTIONS } from './AlertModal.constants';

function AlertModal(props: any, ref: Ref<TAlertModalRef>): ReactElement {
  const [
    {
      title,
      titleValues,
      titleId,
      text,
      textValues,
      textId,
      type = TAlertModalType.error,
      bottomContent,
      alwaysOpen = false,
      showCancelButton,
      onCancel,
    },
    setModalOptions,
  ] = useState<TAlertModalShowOptions>(INITIAL_STATE);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  useImperativeHandle(ref, () => ({
    show,
    hide,
  }));

  function handleOnCancel() {
    if (!alwaysOpen) {
      hide();
    }
  }

  function show(options: TAlertModalShowOptions) {
    setModalOptions({ ...INITIAL_STATE, ...options });
    setIsModalOpen(true);
  }

  function hide() {
    setModalOptions({ ...INITIAL_STATE });
    setIsModalOpen(false);
    onCancel && onCancel();
  }

  return (
    <Modal
      display="flex"
      flexDirection="column"
      hideCloseButton={alwaysOpen || !showCancelButton}
      isOpen={alwaysOpen || isModalOpen}
      onCancel={handleOnCancel}>
      <Box alignment="center">
        <Box width={81} height={81} marginBottom="l">
          <Lottie
            style={{ marginBottom: 16 }}
            options={ALERT_MODAL_LOTTIE[type] || SUCCESS_LOTTIE_OPTIONS}
          />
        </Box>
        <Box mb="l">
          <Text
            marginBottom="s"
            values={titleValues}
            textId={titleId as TI18nId}
            variant="titleSubsection"
            textAlign="center">
            {title}
          </Text>
          <Text
            values={textValues}
            textId={textId as TI18nId}
            color="textSecondary"
            textAlign="center">
            {text}
          </Text>
        </Box>
      </Box>
      {bottomContent}
    </Modal>
  );
}

export default React.forwardRef<TAlertModalRef>(AlertModal);
