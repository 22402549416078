import { BREAK_POINTS } from './media.constants';
import { TBreakPointSize } from './media.types';

function customMediaQuery(breakPointSize: TBreakPointSize): string {
  return `@media (min-width: ${BREAK_POINTS[breakPointSize]})`;
}

const media = {
  xs: customMediaQuery('xSmall'),
  s: customMediaQuery('small'),
  m: customMediaQuery('medium'),
  l: customMediaQuery('large'),
  xl: customMediaQuery('xLarge'),
};

export default media;
