import React, { ReactElement } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { TChildrenOnly } from 'types/common';
import ThemeProvider from './ThemeProvider';
import FetchProvider from './FetchProvider';
import PaymentLinkProvider from './PaymentLinkProvider';
import InvoiceProvider from './InvoiceProvider';

function AllContextProvider({ children }: TChildrenOnly): ReactElement {
  return (
    <BrowserRouter>
      <FetchProvider>
        <ThemeProvider>
          <PaymentLinkProvider>
            <InvoiceProvider>{children}</InvoiceProvider>
          </PaymentLinkProvider>
        </ThemeProvider>
      </FetchProvider>
    </BrowserRouter>
  );
}

export default AllContextProvider;
