import React from 'react';
import { useTheme } from 'styled-components';

import { TIconProps } from '../Icon.types';

function Hourglass01Icon({
  width = 24,
  height = 24,
  strokeWidth = 2,
  vectorEffect = 'default',
  iconColor = 'textPrimary',
}: TIconProps) {
  const { colors } = useTheme();

  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
      <path
        d="M12 12 7.727 8.44c-.635-.53-.952-.794-1.18-1.119a3 3 0 0 1-.444-.947C6 5.991 6 5.578 6 4.752V2m6 10 4.273-3.56c.635-.53.952-.794 1.18-1.119a3 3 0 0 0 .444-.947C18 5.991 18 5.578 18 4.752V2m-6 10-4.273 3.56c-.635.53-.952.794-1.18 1.119a3 3 0 0 0-.444.947C6 18.009 6 18.422 6 19.248V22m6-10 4.273 3.56c.635.53.952.794 1.18 1.119a3 3 0 0 1 .444.947c.103.383.103.796.103 1.622V22M4 2h16M4 22h16"
        stroke={colors[iconColor as never]}
        strokeWidth={strokeWidth}
        vectorEffect={vectorEffect}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Hourglass01Icon;
