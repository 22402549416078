import lightThemePrimitives from './lightThemePrimitives';
import spacing from './spacing';
import sizing from './sizing';
import animation from './animation';
import borders from './borders';
import BREAKPOINTS from './breakpoints';
import { TThemePrimitives, TTheme } from './theme.types';

function createTheme(themePrimitives: TThemePrimitives): TTheme {
  return {
    colors: themePrimitives,
    space: spacing,
    sizing,
    animation,
    borders,
    breakpoints: BREAKPOINTS,
  };
}

export const lightTheme = createTheme(lightThemePrimitives);
