import React from 'react';

import Shimmer from 'components/core/Shimmer';

import { getButtonSize } from '../Button.helpers';
import { TButtonShimmerProps } from '../Button.types';

function ButtonShimmer({ size = 'medium', block = true, ...rest }: TButtonShimmerProps) {
  const buttonSize = getButtonSize({ size });

  return (
    <Shimmer
      shape="square"
      width={block ? '100%' : '40%'}
      height={buttonSize.minHeight}
      {...rest}
    />
  );
}

export default ButtonShimmer;
