import { ASYNC_NOOP, NOOP } from 'constants/noop';

import { TPaymentLinkState, TPaymentLinkDispatch } from './PaymentLinkProvider.types';

export const INITIAL_STATE: TPaymentLinkState = {
  paymentLink: null,
  loading: true,
};

export const INITIAL_DISPATCH: TPaymentLinkDispatch = {
  setPaymentLinkDetail: NOOP,
  retrievePaymentLinkDetail: ASYNC_NOOP,
};
