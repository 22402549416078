import styled, { css, CSSObject } from 'styled-components';
import {
  space,
  color,
  layout,
  variant,
  flexbox,
  display,
  border,
  position,
  grid,
} from 'styled-system';

import { getSpacing } from 'helpers/theme';

import { TStyledBoxProps } from './Box.types';

const alignment = variant({
  prop: 'alignment',
  variants: {
    center: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    row: {
      verticalCenter: {
        display: 'flex',
        alignItems: 'center',
      },
      horizontalCenter: {
        display: 'flex',
        justifyContent: 'center',
      },
      top: {
        left: {
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
        },
        right: {
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'flex-end',
        },
      },
      bottom: {
        left: {
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'flex-start',
        },
        right: {
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'flex-end',
        },
      },
    },
    col: {
      verticalCenter: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      },
      horizontalCenter: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
      top: {
        left: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
        },
        right: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          justifyContent: 'flex-start',
        },
      },
      bottom: {
        left: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          justifyContent: 'flex-start',
        },
        right: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          justifyContent: 'flex-end',
        },
      },
    },
  },
});

const reset: CSSObject = {
  boxSizing: 'border-box',
  minWidth: 0,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const StyledBox = styled.div<TStyledBoxProps & any>`
  ${reset}
  ${alignment}
  ${space}
  ${color}
  ${layout}
  ${flexbox}
  ${display}
  ${border}
  ${position}
  ${grid}
  ${({ gap }) =>
    gap &&
    css`
      gap: ${getSpacing(gap)};
    `}
  ${({ pointerEvents }) =>
    pointerEvents &&
    css`
      pointer-events: ${pointerEvents};
    `}
`;
