import styled from 'styled-components';

import media from 'helpers/media';

export const MainContent = styled.main`
  display: grid;
  gap: 1rem;
  min-height: 100vh;
  box-sizing: border-box;
  background-color: ${(props) => props.theme.colors.backgroundPrimary};
  grid-template-areas:
    'left'
    'right';
  grid-template-columns: 1fr;
  padding: 0 1.5rem;

  ${media.m} {
    grid-template-areas: 'left right';
    grid-template-columns: 1fr 1fr;
  }
`;

export const LeftContent = styled.section`
  display: grid;
  width: 100%;
  grid-area: left;
`;

export const LeftContainer = styled.div`
  max-width: 28rem;
  width: 100%;
  margin: 0 auto;
`;

export const RightContent = styled.section`
  display: grid;
  grid-area: right;
  width: 100%;
  margin-top: 1.5rem;

  ${media.m} {
    margin-top: 0;
    max-width: 100%;
  }
`;
