import React from 'react';

import Box from 'components/core/Box';
import ActivityIndicator from 'components/core/ActivityIndicator';

import { useInvoicePreviewContext } from 'context/InvoiceProvider';

function InvoicePdfPreview() {
  const { invoicePreviewState } = useInvoicePreviewContext();

  return (
    <Box backgroundColor="backgroundSecondary" p="1rem" borderRadius="1.5rem" mt="1rem" mb="1rem">
      {invoicePreviewState.invoicePreviewDetail?.pdfDocumentLink ? (
        <embed
          src={`${invoicePreviewState.invoicePreviewDetail.pdfDocumentLink}#toolbar=0&navpanes=0&scrollbar=0`}
          type="application/pdf"
          width="100%"
          height="100%"
        />
      ) : (
        <Box mt="xxl" alignment="center">
          <ActivityIndicator />
        </Box>
      )}
    </Box>
  );
}

export default InvoicePdfPreview;
