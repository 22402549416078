import useFetch from 'use-http';

import { TAlertModalType } from 'components/AlertModal';

import API_ENDPOINT from 'constants/apiEndpoint';
import { TCommonResponse } from 'types/common';
import WAMO_PAGE from 'constants/wamoPage';
import ERROR_CODES from 'constants/errorCodes';
import { alertModalRef } from 'App';

import {
  TRetrievePaymentLinkDetailParams,
  TPaymentPageDetailResponse,
} from './PaymentLinkProvider.types';

export function usePaymentLink() {
  const { get: requestGetPaymentLinkDetail, response } = useFetch<
    TCommonResponse<TPaymentPageDetailResponse>
  >(API_ENDPOINT.PAYMENT_PAGE_INFO);

  async function retrievePaymentLinkDetail({
    referenceCode,
    onSuccess,
  }: TRetrievePaymentLinkDetailParams) {
    try {
      const data = await requestGetPaymentLinkDetail(`/${referenceCode}`);
      const isAlreadyPaid = data?.errorCode === ERROR_CODES.IS_ALREADY_PAID;
      const isAlreadyCanceled = data?.errorCode === ERROR_CODES.IS_ALREADY_CANCELED;

      if (isAlreadyPaid || isAlreadyCanceled) {
        return alertModalRef.current?.show({
          title: isAlreadyPaid ? 'Success' : 'Warning',
          text: data.errorMessage,
          type: isAlreadyPaid ? TAlertModalType.success : TAlertModalType.warning,
          onCancel: () => window.location.replace(WAMO_PAGE.HOME),
        });
      }

      if (response.status >= 500) {
        return alertModalRef.current?.show({
          titleId: 'error.generic',
          textId: 'error.generic.description',
          type: TAlertModalType.error,
          alwaysOpen: true,
        });
      }

      if (data?.errorCode) {
        window.location.href = WAMO_PAGE.HOME;
        return undefined;
      }

      if (data?.result?.onlinePaymentEnabled === false) {
        return alertModalRef.current?.show({
          titleId: 'page.onlinePaymentNotEnabled.title',
          textId: 'page.onlinePaymentNotEnabled.description',
          type: TAlertModalType.warning,
          alwaysOpen: true,
        });
      }

      return onSuccess({
        ...data.result,
        linkReferenceCode: referenceCode,
      });
    } catch (e) {
      return alertModalRef.current?.show({
        titleId: 'error.generic',
        type: TAlertModalType.error,
        onCancel: () => {
          window.location.href = WAMO_PAGE.HOME;
        },
      });
    }
  }

  return {
    retrievePaymentLinkDetail,
  };
}
