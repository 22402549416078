import React from 'react';

import { TIconProps } from '../Icon.types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const EurIcon = ({ iconColor, ...rest }: TIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48px"
    height="48px"
    viewBox="0 0 48 48"
    fill="none"
    {...rest}>
    <g clipPath="url(#a)">
      <path
        fill="#1A2A6C"
        d="M24 47.998c13.255 0 24-10.745 24-24s-10.745-24-24-24-24 10.745-24 24 10.745 24 24 24Z"
      />
      <path
        fill="gold"
        d="m24 7.215.885 2.757h2.865l-2.318 1.704.886 2.758L24 12.729l-2.318 1.705.886-2.758-2.318-1.704h2.865L24 7.215ZM12.162 12.159l2.552 1.3 2.026-2.025-.448 2.828 2.552 1.3-2.83.449-.447 2.829-1.3-2.552-2.83.448 2.026-2.025-1.3-2.553ZM7.219 23.996l2.757-.885v-2.865l1.704 2.318 2.758-.886-1.705 2.318 1.704 2.318-2.757-.886-1.704 2.318v-2.865l-2.757-.885ZM12.162 35.834l1.3-2.553-2.024-2.025 2.829.448 1.3-2.552.448 2.83 2.829.447-2.552 1.3.448 2.83-2.025-2.026-2.553 1.3ZM24 40.777l-.885-2.757H20.25l2.318-1.704-.886-2.757L24 35.263l2.318-1.704-.886 2.757 2.318 1.704h-2.865L24 40.777ZM35.838 35.834l-2.553-1.3-2.025 2.025.448-2.83-2.552-1.3 2.83-.448.447-2.829 1.3 2.552 2.83-.448-2.026 2.026 1.3 2.552ZM40.781 23.996l-2.757.885v2.865l-1.704-2.318-2.758.886 1.705-2.318-1.705-2.317 2.758.885 1.704-2.318v2.865l2.757.885ZM35.838 12.158l-1.3 2.553 2.025 2.025-2.83-.448-1.3 2.552-.448-2.83-2.829-.447 2.552-1.3-.448-2.83 2.025 2.026 2.553-1.3Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h48v48H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default EurIcon;
