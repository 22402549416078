import React from 'react';

import Shimmer from 'components/core/Shimmer';

import { textVariants } from 'theme/variants';

import { TTextShimmerProps } from '../Text.types';

function TextShimmer({ variant = 'textBody', ...rest }: TTextShimmerProps) {
  const height = textVariants[variant].lineHeight;

  return <Shimmer shape="line" height={height} {...rest} />;
}

export default TextShimmer;
