/* eslint-disable no-multi-assign */
import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import { TMainLayoutProps } from './MainLayout.types';
import { MainContent, LeftContent, RightContent } from './MainLayout.styles';

function MainLayout({ titleId, description, right, children }: TMainLayoutProps): ReactElement {
  const { t } = useTranslation();

  useEffect(() => {
    window.history.replaceState({}, document.title);
  }, []);

  return (
    <>
      <Helmet>
        <title>{t(titleId)}</title>
        <meta name="description" content={description} />
      </Helmet>
      <MainContent>
        <LeftContent>{children}</LeftContent>
        <RightContent>{right}</RightContent>
      </MainContent>
    </>
  );
}

export default MainLayout;
