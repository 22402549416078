import React, { useRef, ReactElement } from 'react';

import useOnClickOutside from 'hooks/useOnClickOutside';

import ModalCore from './ModalCore';
import { ModalContainer, ModalContent } from './Modal.style';
import { TModalProps } from './Modal.types';
import Button from '../Button';

function Modal({
  isOpen = false,
  blur,
  children,
  onCancel,
  hideCloseButton,
  ...restProps
}: TModalProps): ReactElement {
  const ref = useRef(null);
  useOnClickOutside(ref, onCancel);

  return isOpen ? (
    <ModalCore blur={blur}>
      <ModalContainer>
        <ModalContent ref={ref} {...restProps}>
          {children}
          {!hideCloseButton && <Button labelId="button.close" onClick={onCancel} />}
        </ModalContent>
      </ModalContainer>
    </ModalCore>
  ) : (
    <> </>
  );
}

export default Modal;
